












































































































import { Component, Vue } from "vue-property-decorator";
import AppAddressBlock from "@/components/core/AppAddressBlock.vue";
import AppAlert from "@/components/core/AppAlert.vue";
import AppButton from "@/components/core/AppButton.vue";
import AppCard from "@/components/core/AppCard.vue";
import AppCardDeck from "@/components/core/AppCardDeck.vue";
import AppCreateAddressModal from "@/components/modals/AppCreateAddressModal.vue";
import AppListGroup from "@/components/core/AppListGroup.vue";
import AppListGroupItem from "@/components/core/AppListGroupItem.vue";
import AppSearch from "@/components/core/AppSearch.vue";
import TheHeader from "@/components/TheHeader.vue";
import TheMain from "@/components/TheMain.vue";
import {
  searchAddresses,
  updateUser,
  deleteAddress as deleteAddressRequest,
} from "@/utils/api";
import { currentUser, syncUserProfile } from "@/utils/authentication";
import shipmentStore from "@/store/shipment";
import cartHandler from "@/store/cart";
import { Notification, createErrorNotification } from "@/utils/notification";

@Component({
  components: {
    AppAddressBlock,
    AppAlert,
    AppButton,
    AppCard,
    AppCardDeck,
    AppCreateAddressModal,
    AppListGroup,
    AppListGroupItem,
    AppSearch,
    TheHeader,
    TheMain,
  },
  methods: { searchAddresses },
})
export default class AddressBook extends Vue {
  changesMade = false;
  deletedAddresses: pro.Id[] = [];
  notification: Notification | null = null;
  user: pro.User | null = null;
  showModal = false;

  get userId(): pro.Id {
    return this.$route.params.userId;
  }

  get addressType(): pro.AddressType | undefined {
    return this.$route.query.addressType as pro.AddressType | undefined;
  }

  get defaultAddress(): pro.Address | null {
    if (!this.user || !this.user.defaultAddress) return null;
    return this.user?.defaultAddress;
  }

  get isSelectable(): boolean {
    return typeof this.addressType != "undefined";
  }

  mounted() {
    this.user = currentUser();
  }

  /**
   * Filters Address search results
   * IMPORTANT: Anything filtered here will not be reflected in the search
   * record count.
   */
  filteredAddresses(addresses: pro.Address[]): pro.Address[] {
    return addresses.filter(address => {
      return !address.id || !this.deletedAddresses.includes(address.id);
    });
  }

  /**
   * Checks whether an Address was deleted
   */
  wasDeleted(address: pro.Address): boolean {
    if (!address.id) return false;
    return this.deletedAddresses.includes(address.id);
  }

  /**
   * Checks whether an Address may be deleted
   */
  isDeletable(address: pro.Address): boolean {
    return (
      this.isAddressCreator(address) &&
      !this.isDefaultAddress(address) &&
      typeof this.addressType != "undefined"
    );
  }

  /**
   * Redirects the User upon Address selection
   */
  selectAddress(address: pro.Address) {
    if (this.addressType == "from") {
      shipmentStore.setFromAddress(address);
      return this.$router.push({
        name: "SHIPMENT_CREATE",
        params: { catalogId: String(shipmentStore.catalogId) },
      });
    } else if (this.addressType == "to") {
      cartHandler.updateConsignee(address);
      return this.$router.push({
        name: "ORDER_CART",
        params: { catalogId: String(cartHandler.catalogId) },
      });
    }
  }

  /**
   * Checks if an address was created by the current user
   */
  isAddressCreator(address: pro.Address): boolean {
    if (!this.user) return false;
    return address.createdBy?.id == this.user.id;
  }

  /**
   * Checks if an address is the current user's default address
   */
  isDefaultAddress(address: pro.Address): boolean {
    if (!this.defaultAddress) return false;
    return this.defaultAddress?.id == address.id;
  }

  /**
   * Saves an Address as the User's default Address
   */
  async setDefaultAddress(address: pro.Address): Promise<void> {
    const defaultAddress = this.defaultAddress;
    if (
      !this.isDefaultAddress(address) &&
      this.isAddressCreator(address) &&
      this.user
    ) {
      this.user.defaultAddress = address;
      try {
        await updateUser({
          id: this.user.id,
          defaultAddress: { id: address.id },
        });
        await syncUserProfile();
      } catch (err) {
        this.user.defaultAddress = defaultAddress ?? undefined;
      }
    }
  }

  /**
   * Deletes an Address
   */
  async deleteAddress(address: pro.Address): Promise<void> {
    if (!address.id) return;
    try {
      if (this.isDefaultAddress(address))
        throw new Error("You cannot delete your default address.");
      await deleteAddressRequest(address);
      this.deletedAddresses.push(address.id);
      this.changesMade = true;
    } catch (err) {
      this.notification = createErrorNotification(
        "Unable to delete address",
        err.message
      );
    }
  }
}
