











































































































































































































































import { Component, Vue } from "vue-property-decorator";
import AppAddressBlock from "@/components/core/AppAddressBlock.vue";
import AppAlert from "@/components/core/AppAlert.vue";
import AppButton from "@/components/core/AppButton.vue";
import AppCard from "@/components/core/AppCard.vue";
import AppCardDeck from "@/components/core/AppCardDeck.vue";
import AppCheckbox from "@/components/core/AppCheckbox.vue";
import AppInput from "@/components/core/AppInput.vue";
import AppLabel from "@/components/core/AppLabel.vue";
import AppModal from "@/components/core/AppModal.vue";
import AppSelect from "@/components/core/AppSelect.vue";
import { createAddress, validateAddress } from "@/utils/api";
import { createErrorNotification, Notification } from "@/utils/notification";
import { STATES } from "@/main";

@Component({
  components: {
    AppAddressBlock,
    AppAlert,
    AppButton,
    AppCard,
    AppCardDeck,
    AppCheckbox,
    AppInput,
    AppLabel,
    AppModal,
    AppSelect,
  },
})
export default class AppCreateAddressModal extends Vue {
  address: pro.Address = { country: "US", isResidence: false };
  suggestedAddress: pro.Address | null = null;
  notification: Notification | null = null;
  saving = false;
  states = STATES;
  status: string | null = null;
  validationMessages: string[] = [];

  async validateAddress(): Promise<void> {
    this.saving = true;
    this.notification = null;

    try {
      const res = await validateAddress(this.address);
      if (res.matchedAddress) {
        this.suggestedAddress = {
          ...res.matchedAddress,
          emailAddress: this.address.emailAddress,
        };
      }
      this.status = res.status;
      if (res.status != "verified" && res.status != "warning")
        this.validationMessages = res.messages;
    } catch (err) {
      this.notification = createErrorNotification("Error", err.message);
    } finally {
      this.saving = false;
    }
  }

  async saveAddress(address: pro.Address): Promise<void> {
    this.saving = true;
    this.notification = null;

    try {
      this.address = await createAddress(address);
      await this.$emit("address-selected", this.address);
      await this.reset();
      await this.$emit("close");
    } catch (err) {
      this.notification = createErrorNotification("Error", err.message);
    } finally {
      this.saving = false;
    }
  }

  reset(): void {
    const form = document.getElementById(
      "createAddress"
    ) as HTMLFormElement | null;
    if (form) form.reset();
    this.address = { country: "US", isResidence: false };
    this.suggestedAddress = this.status = null;
    this.validationMessages = [];
  }

  makeChanges(address: pro.Address): void {
    this.address = address;
    this.suggestedAddress = this.status = null;
    this.validationMessages = [];
  }
}
